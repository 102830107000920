<template>
  <section class="page-bg">
    <div class="banner page-container" id="Weare"></div>
    <h2 class="yuanjin">Introduction</h2>
    <p class="company-intro page-container">
      <span>ドルフィンAIは自社開発のAI技術を持つ研究開発型のハイテク企業です。</span>
      <span>独自技術の音声対話意図理解モデル(Dolphin Large Language Models)に基づき、音声認識、音声生成、意味理解、テキスト生成、機械翻訳、声紋認識、その他音声対話のフルチェーン技術を開発し、日本語、英語、中国語、韓国語、フランス語、スペイン語、ロシア語、ドイツ語、チベット語、タイ語、ベトナム語など20以上の言語に対応しています。</span>
      <span>また、SaaSやオンプレミスのサービス形態を通じて、教育、医療、金融、その他の分野のお客様に基礎となるAI技術と業界ソリューションを提供しています。</span>
    </p>
    <div class="address page-container" id="Profile">
      <h2>Profile</h2>
      <ul>
        <li>
          <span class="title">会社名</span>
          <span class="info">株式会社Dolphin AI</span>
        </li>
        <li>
          <span class="title">設立</span>
          <span class="info">2023年12月</span>
        </li>
<!--        <li>-->
<!--          <span class="title">資本金</span>-->
<!--          <span class="info">3,000,000円</span>-->
<!--        </li>-->
        <li>
          <span class="title">代表取締役社長</span>
          <span class="info">朝倉(アサクラ) 匡廣(マサヒロ)</span>
        </li>
        <li>
          <span class="title">事業内容</span>
          <ol>
            <li>DolphinSOE 英語発音評価サービスの開発＆販売</li>
            <li>DolphinVoice 音声対話SaaS Platformの開発＆販売</li>
          </ol>
        </li>
        <li>
          <span class="title">アクセス情報</span>
          <div class="map">
            <p>〒170-0013</p>
            <p>東京都豊島区東池袋1-18-1 Hareza Tower 20F</p>
            <ol>
              <li>JR山手線・埼京線 池袋駅東口（30番出口）より徒歩4分</li>
              <li>東京メトロ丸の内線・副都心線・有楽町線 池袋駅東口（30番出口）より徒歩4分</li>
              <li>西武池袋線 池袋駅東口（30番出口）より徒歩4分</li>
              <li>東武東上線 池袋駅東口（30番出口）より徒歩4分</li>
            </ol>
            <div>
              <iframe
                  src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d1619.4010139286677!2d139.71289824932452!3d35.731087078279494!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e3!4m5!1s0x60188d5d4043e0dd%3A0x213775d25d2b034d!2z5p2x5Lqs6YO96LGK5bO25Yy65Y2X5rGg6KKL77yR5LiB55uu77yS77yYIOaxoOiii-mnhQ!3m2!1d35.7295028!2d139.7109001!4m5!1s0x60188d99ee19b98b%3A0x287b2cf80a55fb87!2z5p2x5Lqs6YO96LGK5bO25Yy65p2x5rGg6KKL77yR5LiB55uu77yR77yY4oiS77yRIEhhcmV6YSBUb3dlcg!3m2!1d35.7324188!2d139.7152077!5e0!3m2!1sja!2sjp!4v1703747968784!5m2!1sja!2sjp"
                  width="816"
                  height="216"
                  style="border:0;"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </li>
        <li>
          <span class="title">電話番号</span>
          <span class="info">(+81) 03-6775-4523</span>
        </li>
        <li>
          <span class="title">メールアドレス</span>
          <span class="info">contact@dolphin-ai.jp</span>
        </li>
      </ul>
    </div>
    <div class="founder" id="Founder">
      <h2>Founder</h2>
      <div class="member">
        <div class="member-container" @mouseenter="handleHover(true, 'coo')" @mouseleave="handleHover(false, 'coo')">
          <div class="member-intro">
            <div class="infos">
              <p class="name">朝倉(アサクラ) 匡廣(マサヒロ)</p>
              <p class="position">CEO (Chief Executive Officer 代表取締役社長)</p>
            </div>
            <p class="photo">
              <img src="~@/assets/images/company/coo.png">
            </p>
            <div class="intro-btn">
              <img src="~@/assets/images/company/member_btn.png">
            </div>
          </div>
          <div class="intro-details" ref="coo">
            <span>富士通、Bell System24、Advanced Mediaなど日本大手IT＆AI事業会社で、プロダクト＆マーケティング＆海外事業開発などエンジニアや管理職歴任。</span>
            <span>物流、コールセンター、AI＆IOT領域で新規事業0-1、1-10経験豊富。</span>
            <span>AI＆IOT領域で10年ぐらい日本国内＆海外において外部アライアンス、プロダクト企画、マーケティング、企業案件導入など実績多数。</span>
          </div>
        </div>
        <div class="member-container" @mouseenter="handleHover(true, 'ceo')" @mouseleave="handleHover(false, 'ceo')">
          <div class="member-intro">
            <div class="infos">
              <p class="name">党(トウ) 建武(タケシ)</p>
              <p class="position">CSO (Chief Scientific Officer 主任研究員)</p>
            </div>
            <p class="photo">
              <img src="~@/assets/images/company/ceo.png">
            </p>
            <div class="intro-btn">
              <img src="~@/assets/images/company/member_btn.png">
            </div>
          </div>
          <ul class="intro-details" ref="ceo">
            <li>1982年 中国清華大卒業，84年同大修士</li>
            <li>1984-1988年 天津大学講師</li>
            <li>1992年 静岡大大学院博士課程博士（工学）</li>
            <li>1992-2001年 ATR 人間情報通信研究所主任研究員</li>
            <li>1998-1999年 カナダWaterloo大学工学科客員研究員を兼任</li>
            <li>2001-2022年 北陸先端科学技術大学院大学教授</li>
            <li>2002-2003年 仏国立科学研究センター音声通信研究所＆グルノーブル工科大＆ステンダル大など研究員を兼任</li>
            <li>2022年-現在 北陸先端科学技術大学院大学名誉教授 </li>
            <li>音声生成・合成，音声認識，言語モデル，言語障害，ジェネレーティブAIなどに従事。</li>
            <li>電信通信学会，日本音響学会，ASA，ACMなどの会員で、JASA、IEEE Trans、Speech Communication，Neuroscienceなどの国際有名学術雑誌で100本以上論文発表。</li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'company',
  mounted () {
    this.$nextTick(() => {
      this.advScroll (this.$route.query.id)
    })
  },
  watch: {
    $route() {
      this.advScroll (this.$route.query.id)
    }
  },
  methods: {
    handleHover (bol, refName) {
      if (bol) {
        this.$refs[refName].classList.add("mouse-enter")
        this.$refs[refName].classList.remove("mouse-leave")
      } else {
        this.$refs[refName].classList.add("mouse-leave")
        this.$refs[refName].classList.remove("mouse-enter")
      }
    },
    advScroll (targetID) {
      if (targetID !== 'Weare') {
        const targetElement = document.querySelector(`#${targetID}`)
        targetElement.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        })
      } else {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
h2 {
  font-weight: 500;
  font-size: 40px;
  font-weight: 500;
  letter-spacing: 0;
  color: rgba(47, 41, 223, 1);
  text-align: left;
  vertical-align: top;
  margin-bottom: 30px;
}
.yuanjin {
  width: 1200px;
  margin: 60px auto 0;
}
.banner {
  background: url('../assets/images/company/company_banner.jpg') no-repeat left top;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.company-intro {
  display: flex;
  flex-wrap: wrap;
  min-height: 200px;
  padding-top: 30px;
  padding-bottom: 60px;
  font-weight: 500;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 28px;
  color: rgba(0, 0, 0, 1);
  text-align: left;
  vertical-align: top;
  background: url('../assets/images/grid_bg.png') no-repeat -200px -160px;
  background-size: 1360px 787px;
  span {
    width: 100%;
    margin: 15px 0;
  }
}
.address {
  min-height: 400px;
  padding-bottom: 60px;
  h2 {
    margin-bottom: 20px;
  }
  ul {
    >li {
      min-height: 60px;
      line-height: 60px;
      border-bottom: 1px solid rgba(153, 153, 153, 1);
      display: flex;
      .title {
        width: 300px;
        flex-shrink: 0;
        font-weight: 500;
        font-size: 20px;
        letter-spacing: 0;
        color: rgba(0, 0, 0, 1);
        text-align: left;
        vertical-align: middle;
      }
      .info {
        flex: 1;
      }
      .info, .map>p, ol>li {
        font-size: 20px;
        letter-spacing: 0;
        color: rgba(0, 0, 0, 1);
        text-align: left;
        vertical-align: middle;
      }
      .map >p, .map >ol >li {
        line-height: 50px;
      }
      .map {
        padding-top: 20px;
      }
    }
    >li:nth-of-type(9) {
      border-bottom: none;
    }
  }
  .map, .map>div {
    width: 100%;
  }
  .map>div {
    height: 216px;
    margin-bottom: 20px;
  }
  .map>div>iframe {
    width: 100%!important;
    height: 100%!important;
  }
}
ol {
  padding-left: 40px;
  >li {
    list-style: disc;
  }
}
.founder {
  height: 960px;
  h2 {
    width: 1200px;
    margin: 0 auto 20px;
  }
  .member {
    position: relative;
    display: flex;
    justify-content: space-between;
    height: 586px;
    padding: 0 360px;
    background: url('../assets/images/company/founder.jpg') no-repeat left top;
    background-size: 100% 100%;
  }
  .member-container {
    width: 532px;
    height: 720px;
    border-radius: 28px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(234, 230, 242, 1) 100%);
    position: relative;
    top: 44px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
  .member-container:nth-of-type(1) {
    margin-right: 46px;
  }
  .infos {
    position: absolute;
    z-index: 4;
    top: 30px;
    left: 48px;
    .name {
      font-weight: 500;
      font-size: 20px;
      letter-spacing: 0;
      line-height: 60px;
      color: rgba(60, 60, 60, 1);
      text-align: left;
      vertical-align: top;
    }
    .position {
      font-size: 20px;
      font-weight: 400;
      letter-spacing: 0;
      line-height: 40px;
      color: rgba(60, 60, 60, 1);
      text-align: left;
      vertical-align: top;
    }
  }
  .photo {
    width: 258px;
    height: 258px;
    background: url('../assets/images/company/member_bg.png') no-repeat left top;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 220px;
      height: 220px;
    }
  }
  .intro-btn {
    width: 54px;
    height: 54px;
    opacity: 1;
    border-radius: 27px;
    position: absolute;
    bottom: 80px;
    left: 50%;
    transform: translateX(-50%);
    img {
      width: 54px;
      height: 54px;
    }
  }
  .intro-details {
    position: absolute;
    width: 422px;
    height: 556px;
    padding: 172px 55px 0;
    left: 0;
    top: 0;
    opacity: 1;
    border-radius: 28px;
    background: rgba(225, 227, 240, 1);
    >li {
      margin-bottom: 5px;
      list-style: disc;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 26px;
      color: rgba(0, 0, 0, 1);
      text-align: left;
      vertical-align: top;
    }
  }
  div.intro-details {
    display: flex;
    flex-wrap: wrap;
    align-content: baseline;
    font-size: 16px;
    letter-spacing: 0;
    color: rgba(0, 0, 0, 1);
    text-align: left;
    vertical-align: top;
    span {
      width: 100%;
      margin-bottom: 15px;
    }
  }
  .intro-details {
    transform: translateY(728px);
  }
  .intro-details.mouse-enter {
    transform: translateY(0);
    transition: transform 0.5s linear;
  }
  .intro-details.mouse-leave {
    transform: translateY(728px);
    transition: transform 0.5s linear;
  }
}
</style>
